.section-city {
  background-color: #f4f4f4;
  @include imageBg($image: "bg-cidade.jpg", $pos: center bottom);
  @include flex($align: center);
  min-height: 800px;
  padding: 50px 0;
  position: relative;
  width: 100vw;

  .box-search-city {
    label {
      @include flex($direction: column, $align: flex-start);
      position: relative;

      &:before {
        content: "";
      }

      > .label {
        color: #000000;
        display: block;
        font-size: 22px;
        line-height: 1.2em;
        margin: 0 0 20px;
      }

      > input {
        background-color: transparent;
        @include imageBg($image: "icon-search.png", $pos: 0 center);
        border-color: #bbbbbb;
        border-style: solid;
        border-width: 0 0 1px;
        color: #000000;
        font-size: 28px;
        font-weight: weight(bold);
        line-height: 1em;
        padding: 0 0 0 50px;
        @include sizes(100%, 60px);

        @include placeholder {
          color: #000000;
          opacity: 1;
        }
      }
    }

    .box-list {
      margin: 40px 0 0;
      height: 300px;

      ul.list {
        @include flex($direction: column, $align: flex-start, $wrap: nowrap);

        > li {
          & + li {
            margin: 30px 0 0;
          }

          > a {
            color: $text-color-light;
            display: block;
            font-size: 28px;
            font-weight: weight(medium);
            @include transitions;

            &:hover {
              color: $brand-color;
            }
          }
        }
      }
    }
  }

  @include breakpoint-min(large) {
    height: 100vh;
  }

  @include breakpoint-max(large) {
    background-position: 55% bottom;
    min-height: 1px;
    padding: 30px 0 50px;

    &:before {
      background-color: #f4f4f4;
      content: "";
      left: 0;
      opacity: 0.8;
      position: absolute;
      top: 0;
      @include sizes(100%);
      z-index: 1;
    }

    > .container {
      position: relative;
      z-index: 2;
    }

    .box-search-city {
      label {
        > input {
          font-size: 24px;
          font-weight: weight(medium);
        }
      }

      .box-list {
        ul.list {
          > li {
            > a {
              font-size: 16px;
            }
          }
        }
      }
    }

    .box-image {
      margin: 50px 0 0;
    }
  }
}
