// Font Size Default
$font-size-base: 18;

// Font family Primary
$font-family-primary: 'Barlow', sans-serif !default;

$font-primary-weight: (
        'thin': 100,
        'extralight': 200,
        'light': 300,
        'regular': 400,
        'medium': 500,
        'semibold': 600,
        'bold': 700,
        'extrabold': 800,
        'black': 900,
) !default;

// Colors
$brand-color: #1086ec !default;
$brand-color-secondary: #ec3237 !default;
$brand-color-third: #0b224b !default;
$brand-color-fourth: #ec3237 !default;
$text-color-light: #777777 !default;
$text-color-dark: #333333 !default;
$border-color: #e5e5e5 !default;
$error: #e74c3c !default;
$success: #27ae60 !default;

// Container's maximum width
$max-width: 1200px !default;

// Breakpoints map
// @prop {String} keys - Keys are identifiers mapped to a given length
// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints-max: (
        'small': (max-width: 575px),
        'medium': (max-width: 767px),
        'large': (max-width: 991px),
        'xlarge': (max-width: ($max-width - 1px))
) !default;

// Breakpoints map
// @prop {String} keys - Keys are identifiers mapped to a given length
// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints-min: (
        'small': (min-width: 576px),
        'medium': (min-width: 768px),
        'large': (min-width: 992px),
        'xlarge': (min-width: $max-width)
) !default;

// Bootstrap Variables Overrides
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: $max-width
) !default;

$font-family-base: $font-family-primary;
$font-weight-base: 400;
$line-height-base: 1.2;
$headings-font-family: $font-family-primary;
$headings-font-weight: 700;
$headings-margin-bottom: 20px;

//$enable-transitions: false;
$enable-print-styles: false;
$link-hover-decoration: none;

$spacer: 10px;

$spacers: () !default;
$spacers: map-merge(
                (
                        0: 0,
                        1: ($spacer * 1),
                        2: ($spacer * 2),
                        3: ($spacer * 3),
                        4: ($spacer * 4),
                        5: ($spacer * 5),
                        6: ($spacer * 6),
                        7: ($spacer * 7),
                        8: ($spacer * 8),
                        9: ($spacer * 9),
                        10: ($spacer * 10),
                ),
                $spacers
);