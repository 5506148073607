// Flexbox
.flex {
  &-row {
      @extend %flex;
      flex-direction: row;
  }
  &-row-reverse {
      @extend %flex;
      flex-direction: row-reverse;
  }
  &-column {
      @extend %flex;
      flex-direction: column;
  }
  &-column-reverse {
      @extend %flex;
      flex-direction: column-reverse;
  }
}

// Align Items
.a-start {
  align-items: flex-start;
}
.a-center {
  align-items: center;
}
.a-end {
  align-items: flex-end;
}
.a-stretch {
  align-items: stretch;
}

// Justify Items
.j-start {
  justify-content: flex-start;
}
.j-center {
  justify-content: center;
}
.j-end {
  justify-content: flex-end;
}
.j-around {
  justify-content: space-around;
}
.j-between {
  justify-content: space-between;
}