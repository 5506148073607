.section-residential-plans {
  padding: 120px 0 0;
  position: relative;

  .box-title {
    @include flex($direction: column, $align: center);
    margin: 0 0 60px;
    text-align: center;

    .title {
      color: $text-color-dark;
      font-size: 65px;
      font-weight: weight(light);
      line-height: 1em;
      text-transform: uppercase;

      > strong {
        font-weight: weight("black");
      }
    }

    .description {
      color: $text-color-dark;
      margin: 15px 0 0;

      p {
        font-size: 22px;
        font-weight: weight(regular);
        line-height: 1.2em;
        margin: 0;

        & + p {
          margin: 20px 0 0;
        }
      }

      &.-secondary {
        color: $text-color-dark;

        p {
          & + p {
            margin: 0;
          }
        }
      }
    }
  }

  .box-items {
    > .row {
      margin-bottom: -30px;
      justify-content: center;
    }

    .item {
      margin: 0 0 30px;

      > .inner {
        background-color: #ffffff;
        border-radius: 15px;
        box-shadow: 0 0 15px rgba(#000000, 0.1);
        cursor: default;
        height: 100%;
        margin: 0 auto;
        max-width: 370px;
        padding: 0 0 90px;
        overflow: hidden;
        position: relative;
        @include transitions;
        width: 100%;
      }

      .top {
        @include flex($direction: column, $align: flex-start);
        padding: 35px 35px 0;
        text-align: center;

        .category {
          color: $text-color-light;
          font-size: 20px;
          font-weight: weight(regular);
          text-transform: uppercase;
        }

        .mega {
          color: $text-color-dark;
          font-size: 80px;
          font-weight: weight(bold);
          line-height: 1em;
          position: relative;
          text-transform: uppercase;
        }

        .mega-label {
          font-size: 30px;
          font-weight: weight(medium);
          text-transform: uppercase;
          margin: 5px 0 0;
        }
      }

      .content {
        padding: 30px 35px 35px;
      }

      .items {
        & + .price {
          margin: 30px 0 0;
        }

        > li {
          color: $text-color-light;
          @include flex($align: center);
          font-size: 18px;
          font-weight: weight(medium);
          line-height: 1.2em;
          min-height: 18px;
          padding: 0 0 0 24px;
          position: relative;

          & + li {
            margin: 10px 0 0;
          }

          &::before {
            @include imageBg($image: "icon-check.png");
            content: "";
            left: 0;
            position: absolute;
            top: 2px;
            @include sizes(18px);
          }
        }
      }

      .price {
        color: $text-color-dark;
        @include flex($align: flex-start, $wrap: nowrap, $justify: flex-start);

        > span {
          line-height: 1em;

          &.value {
            font-size: 36px;
            font-weight: weight(medium);
            line-height: 1em;
          }

          &.month {
            align-self: flex-end;
            font-size: 16px;
            font-weight: weight(light);
            margin: 0 0 3px;
          }
        }
      }

      .button {
        bottom: 40px;
        left: 0;
        padding: 0 40px;
        position: absolute;
        width: 100%;

        > a {
          background-color: $brand-color-secondary;
          border-radius: 50px;
          color: #ffffff;
          display: block;
          @include flex($align: center, $justify: center);
          font-size: 20px;
          font-weight: weight(medium);
          padding: 0 0 5px;
          @include transitions;
          @include sizes(100%, 50px);

          &:hover {
            background-color: $brand-color;
          }
        }
      }
    }
  }

  @include breakpoint-max(large) {
    padding: 160px 0 0;

    .box-title {
      .title {
        font-size: 40px;
      }
    }

    .box-plans-title {
      .title {
        font-size: 50px;
      }
    }
  }
}
