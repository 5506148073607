.box-form {
  .form-group {
    position: relative;
  }

  .form-control,
  .custom-select {
    background-color: #ffffff;
    border-width: 1px;
    color: $text-color-dark;
    font-size: 18px;
    font-weight: weight(medium);
    height: 60px;
    resize: none;

    @include placeholder {
      color: $text-color-dark;
      font-size: 18px;
      font-weight: weight(medium);
    }

    &:not(.is-invalid) {
      border-color: $border-color;

      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(#000, 0.1);
      }
    }
  }

  textarea.form-control {
    height: 180px;
  }

  .btn {
    background-color: $brand-color-secondary;
    border: none;
    border-radius: 5px;
    color: #ffffff;
    font-size: 18px;
    font-weight: weight(medium);
    height: 50px;
    padding: 10px 30px;
    @include transitions;

    &:hover {
      background-color: $brand-color-third;
    }
  }

  &-success {
    color: $text-color-dark;
    @include flex($direction: column, $align: flex-start);

    > img {
      margin: 0 0 10px;
    }

    > h3 {
      font-size: 38px;
    }

    > p {
      font-size: 20px;
      font-weight: weight(medium);
      line-height: 1.2em;
    }
  }
}
