.section-about {
  padding: 120px 0 0;
  position: relative;
  z-index: 1;

  .box-content {
    position: relative;
    z-index: 2;

    h2 {
      color: $text-color-dark;
      font-size: 44px;
      font-weight: weight(extrabold);
      line-height: 1.15em;
      margin: 0 0 30px;
      max-width: 500px;
      text-transform: uppercase;

      strong {
        color: $brand-color;
        font-weight: weight(semibold);
      }
    }

    .description {
      p {
        color: $text-color-dark;
        font-size: 22px;
        line-height: em(30, 22);

        & + p {
          margin: 30px 0 0;
        }

        & + h3 {
          margin-top: 30px;
        }
      }

      h3 {
        color: $text-color-dark;
        font-size: 22px;
        margin-bottom: 10px;
      }

      ul {
        li {
          color: $text-color-dark;
          font-size: 22px;
          line-height: em(30, 22);

          & + li {
            margin: 10px 0 0;
          }
        }
      }
    }
  }

  .box-image {
    picture,
    figure {
      margin: 0;
    }
  }

  @include breakpoint-min(large) {
    .box-content {
      padding-top: 80px;
    }
  }

  @include breakpoint-max(large) {
    padding: 160px 0 0;

    .box-content {
      h2 {
        font-size: 30px;
        max-width: none;
      }

      .description {
        max-width: none;

        p {
          font-size: 20px;
        }
      }
    }

    .box-image {
      margin: 50px 0 0;
    }
  }
}
