/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: border-box;
  outline: none !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

// Img
img {
  box-sizing: content-box;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

// Body
body {
  position: relative;
  overflow-y: scroll;
}

// Wrapper
.wrapper {
  overflow: hidden;
}

a {
  line-height: 1.2em;
}