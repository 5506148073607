/* slide */
#slides .sld-titulo {
  font-family: "barlowlight", Arial, serif;
  color: #fff;
  font-size: 42px;
  font-weight: normal;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 1.0em;
  letter-spacing: -1.5px;
  display: block;
  padding: 0 0 20px 90px;
  margin: 0;
}

#slides .sld-titulo strong {
  font-family: "barlowextrabold", Arial, serif;
  display: block;
}

#slides .sld-download {
  font-family: "barlowextrabold", Arial, serif;
  color: #fff;
  font-size: 190px;
  font-weight: normal;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 0.8em;
  letter-spacing: -8px;
  display: inline-block;
  position: relative;
  padding: 0 0 0 90px;
  margin: 0;
}

#slides .sld-mega {
  font-family: "barlowregular", Arial, serif;
  color: #fff;
  font-size: 66px;
  font-weight: normal;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 1.0em;
  display: inline-block;
  position: absolute;
  padding: 0;
  margin: 0 0 0 10px;
}

#slides .sld-ilimitado {
  font-family: "barlowregular", Arial, serif;
  color: #fff;
  font-size: 36px;
  font-weight: normal;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 1.0em;
  display: inline-block;
  position: absolute;
  padding: 0;
  margin: 52px 0 0 12px;
}

#slides .sld-valor {
  font-family: "barlowextrabold", Arial, serif;
  color: #fff;
  font-size: 38px;
  font-weight: normal;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 1.0em;
  display: inline-block;
  position: absolute;
  padding: 0;
  margin: 84px 0 0 13px;
}

#slides .sld-periodo {
  font-family: "barlowmedium", Arial, serif;
  color: #fff;
  font-size: 20px;
  font-weight: normal;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 1.0em;
  display: inline-block;
  position: absolute;
  padding: 0;
  margin: 118px 0 0 13px;
}

#slides .sld-assine {
  text-align: center;
  font-family: "barlowbold", Arial, serif;
  color: #fff;
  font-size: 22px;
  font-weight: normal;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 1.0em;
  display: block;
  padding: 15px 0;
  margin: 10px 0 0 90px;
  background: #0073f4;
  border-radius: 40px;
  -moz-border-radius: 40px;
  -webkit-border-radius: 40px;
}