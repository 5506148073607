.section-testimony {
  background-color: $brand-color-secondary;
  padding: 120px 0;
  position: relative;
  overflow-y: hidden;

  .title {
    color: #ffffff;
    font-size: 70px;
    font-weight: weight('black');
    line-height: 1.2em;
    margin: 0 0 80px;
    text-align: center;
    text-transform: uppercase;
  }

  .box-carousel {
    .swiper-slide {
      .item-text {
        background-color: #ffffff;
        border-radius: 10px 10px 10px 0;
        color: $text-color-dark;
        padding: 40px;
        position: relative;
        z-index: 1;

        &:before {
          border-top: 20px solid transparent;
          border-bottom: 20px solid transparent;
          border-left: 20px solid #ffffff;
          content: "";
          height: 0;
          left: 0;
          position: absolute;
          top: 100%;
          transform: translateY(-50%);
          width: 0;
          z-index: -1;
        }

        p {
          font-size: 18px;
          font-weight: weight(regular);
          line-height: 1.2em;

          & + p {
            margin: 5px 0 0;
          }
        }
      }

      .item-infos {
        margin: 20px 0 0;
        padding: 0 0 0 65px;
        position: relative;
        @include flex(
                $direction: column,
                $align: flex-start
        );

        .item-image {
          border-radius: 50%;
          left: 20px;
          max-width: 36px;
          overflow: hidden;
          position: absolute;
          top: 0;
        }

        .item-title {
          color: #ffffff;
          font-size: 20px;
          position: relative;
        }

        .item-subtitle {
          color: #ffffff;
          font-size: 16px;
          font-weight: weight(medium);
        }
      }
    }

    .testimony-pagination {
      @include flex(
              $align: center,
              $justify: center
      );
      margin: 20px 0 0;
      width: 100%;

      > .bullet {
        background-color: transparent;
        border: solid 2px #ffffff;
        border-radius: 50%;
        display: block;
        @include transitions;
        @include sizes(14px);

        & + .bullet {
          margin: 0 0 0 5px;
        }

        &-active,
        &:hover {
          background-color: #ffffff;
        }
      }
    }
  }

  @include breakpoint-max(large) {
    .title {
      margin: 0 0 40px;
    }
  }

  @include breakpoint-max(medium) {
    .title {
      font-size: 40px;
    }
  }
}