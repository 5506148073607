.box-title {
  margin: 0 0 50px;
  position: relative;

  &.-border {
    padding: 0 0 30px;

    &:before {
      background-color: $brand-color;
      border-radius: 60px;
      bottom: 0;
      content: "";
      left: 0;
      position: absolute;
      @include sizes(60px, 5px);
    }
  }

  .title {
    color: $text-color-dark;
    font-size: 48px;
    line-height: 1em;

    & + .description {
      margin: 10px 0 0;
    }
  }

  .description {
    color: #777777;

    p {

      font-size: 26px;
      font-weight: weight(medium);
      line-height: em(30, 26);

      & + p {
        margin: 10px 0 0;
      }
    }
  }

  @include breakpoint-max(large) {
    .title {
      font-size: 36px;
    }

    .description {
      p {
        font-size: 24px;
      }
    }
  }

  @include breakpoint-max(small) {
    .title {
      font-size: 30px;
    }

    .description {
      p {
        font-size: 20px;
      }
    }
  }
}