.section-contact {
  padding: 120px 0 0;
  position: relative;

  .box-title {
    margin: 0 0 100px;
    text-align: center;

    .title {
      color: $text-color-dark;
      font-size: 50px;
      font-weight: weight(extrabold);
      text-transform: uppercase;
    }

    .description {
      p {
        color: $text-color-light;
        font-size: 36px;
        font-weight: weight(light);
        line-height: 1.25em;
        margin: 10px 0 0;
      }
    }
  }

  .subtitle {
    color: $text-color-dark;
    font-size: 20px;
    font-weight: weight(medium);
    margin: 0 0 20px;
  }

  .contact-infos {
    > h3 {
      color: $text-color-light;
      font-size: 34px;
      font-weight: weight(light);
      margin: 0;
      text-align: center;
    }

    > h4 {
      color: $text-color-dark;
      font-size: 20px;
      font-weight: weight(medium);
      margin: 10px 0 0;
      text-align: center;
    }

    > ul {
      @include flex($align: center, $justify: center);
      margin: 20px 0 0;

      > li {
        & + li {
          margin: 0 0 0 30px;
        }

        > a {
          display: block;
          transform: translateY(0);
          @include transitions;

          &:hover {
            transform: translateY(-3px);
          }
        }
      }
    }

    > .box-items {
      border-top: solid 1px $border-color;
      margin: 20px 0 0;
      padding: 20px 0 0;

      .item {
        text-align: center;

        & + .item {
          border-top: solid 1px $border-color;
          margin: 20px 0 0;
          padding: 20px 0 0;
        }

        > h3 {
          color: $text-color-dark;
          font-size: 20px;
          font-weight: weight(medium);
          margin: 0;

          & + p {
            margin: 5px 0 0;
          }
        }

        > p {
          color: $text-color-light;
          font-size: 20px;
          font-weight: weight(medium);
          line-height: 1.35em;
        }
      }
    }
  }

  @include breakpoint-min(xlarge) {
    .contact-infos {
      padding-left: 80px;
    }
  }

  @include breakpoint-max(large) {
    padding: 160px 0 0;

    .box-title {
      margin: 0 0 50px;

      .title {
        font-size: 40px;
      }

      .description {
        p {
          font-size: 28px;
        }
      }
    }

    .row {
      > div[class*="col-"] {
        & + div {
          margin-top: 50px;
        }
      }
    }

    .contact-infos {
      > ul {
        > li {
          & + li {
            margin: 0 0 0 25px;
          }

          > a {
            font-size: 40px;
          }
        }
      }
    }
  }
}
