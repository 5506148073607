.section-doubts {
  background-color: #ffffff;
  padding: 120px 0;

  .box-title {
    margin: 0 0 60px;
    text-align: center;

    .title {
      font-weight: weight('black');
      text-transform: uppercase;

      & + .description {
        margin: 30px 0 0;
      }
    }

    .description {
      color: $text-color-dark;

      p {
        font-weight: weight(regular);
      }
    }
  }

  .card {
    border: none;

    & + .card {
      border-top: solid 1px $border-color;
      margin: 15px 0 0;
      padding: 15px 0 0;
    }

    .card-header {
      background-color: #ffffff;
      border: none;
      padding: 0;

      h3 {
        color: $text-color-dark;
        cursor: pointer;
        font-size: 20px;
        font-weight: weight(bold);
        margin: 0;
        padding: 0 40px 0 0;
        position: relative;

        &:before,
        &:after {
          background-color: #ffffff;
          color: #000000;
          content: "";
          @include flex(
                  $align: center,
                  $justify: center
          );
          font-family: 'Font Awesome 5 Free';
          font-weight: 900;
          opacity: 1;
          position: absolute;
          right: 0;
          top: 0;
          @include transitions;
          @include sizes(20px, 100%);
          z-index: 1;
        }

        &:before {
          content: "\f055";
        }

        &:after {
          color: #f8234a;
          content: "\f056";
          opacity: 0;
          z-index: 2;
        }

        &[aria-expanded="true"] {
          &:after {
            opacity: 1;
          }
        }
      }
    }

    .card-body {
      padding: 10px 0 0;
    }
  }

  @include breakpoint-min(large) {
    .box-title {
      .title {
        font-size: 60px;
      }

      .description {
        p {
          font-size: 26px;
        }
      }
    }
  }

  @include breakpoint-max(large) {
    padding: 120px 0 0;

    .card {
      .card-header {
        h3 {
          font-size: 18px;
        }
      }
    }
  }
}