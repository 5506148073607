.box-text {
  color: #777777;

  p {
    font-size: 18px;
    line-height: em(24, 18);

    & + p {
      margin: 10px 0 0;
    }
  }
}