.box-social-media {
  > ul {
    @include flex(
            $align: center
    );

    > li {
      & + li {
        margin: 0 0 0 5px;
      }

      > a {
        background-color: $brand-color-secondary;
        color: #ffffff;
        border-radius: 50%;
        @include flex(
                $align: center,
                $justify: center
        );
        position: relative;
        @include sizes(35px);
        @include transitions;

        &:hover {
          background-color: $brand-color-third;
        }
      }
    }
  }
}