.section-slide {
  background-color: #fafafa;
  margin: 0 auto;
  max-width: 1920px;
  position: relative;
  width: 100%;

  .swiper-slide {
    > a {
      display: block;
      position: relative;
      @include sizes(100%);
    }
  }

  .slide-navigation {
    background-color: transparent;
    color: #ffffff;
    border-radius: 3px;
    @include flex($align: center, $justify: center);
    position: absolute;
    top: calc(50% - 30px);
    @include transitions;
    @include sizes(50px, 60px);
    z-index: 2;

    &.prev {
      left: 15px;
    }

    &.next {
      right: 15px;
    }

    &:hover {
      background-color: rgba(#000000, 0.15);
    }
  }

  @include breakpoint-min(large) {
    .slide-pagination {
      display: none;
    }
  }

  @include breakpoint-max(large) {
    .swiper-slide {
    }

    .slide-navigation {
      display: none;
    }

    .slide-pagination {
      bottom: 15px;
      @include flex($align: center, $justify: center);
      left: 0;
      padding: 0 15px;
      position: absolute;
      width: 100%;
      z-index: 3;

      > .bullet {
        background-color: transparent;
        border: solid 2px #ffffff;
        border-radius: 50%;
        display: block;
        opacity: 0.5;
        @include transitions;
        @include sizes(14px);

        & + .bullet {
          margin: 0 0 0 10px;
        }

        &-active,
        &:hover {
          background-color: #ffffff;
          opacity: 1;
        }
      }
    }
  }
}
