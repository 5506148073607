.site-header {
  background-color: #ffffff;
  padding: 10px 0;
  position: relative;

  &.scroll-to-fixed-fixed {
    box-shadow: 3px 3px 10px rgba(#000000, 0.1);
  }

  .row {
    align-items: center;
  }

  .header-links {
    ul {
      @include flex($align: center, $justify: flex-end);

      > li {
        > a {
          @include flex($align: center, $justify: flex-start);
          color: $text-color-dark;
          font-size: 16px;
          font-weight: weight(medium);
          min-height: 20px;
          padding: 0 0 0 25px;
          position: relative;
          @include transitions;

          > i {
            color: $brand-color-fourth;
            @include flex($align: center, $justify: center);
            left: 0;
            position: absolute;
            top: 0;
            @include sizes(20px, 100%);
          }

          &:hover {
            color: $brand-color-fourth;
          }
        }
      }
    }
  }

  .site-nav {
    ul.menu {
      > li {
        > a {
          color: $text-color-dark;
          display: block;
          font-size: 18px;
          font-weight: weight(semibold);
          padding: 0;
          text-transform: uppercase;
          @include transitions;

          &.active,
          &:hover {
            color: $brand-color;
          }
        }
      }
    }
  }

  @include breakpoint-min(large) {
    .btn-toggle-menu {
      display: none;
    }

    .header-links {
      margin: 0 0 30px;

      ul {
        > li {
          & + li {
            margin: 0 0 0 20px;
          }
        }
      }
    }

    .site-nav {
      .toggle-menu {
        display: block !important;
        height: auto !important;
        overflow: visible !important;

        &.mobile {
          display: block !important;
        }
      }

      ul.menu {
        @include flex($align: center, $justify: flex-end);

        > li {
          & + li {
            margin: 0 0 0 20px;
          }
        }
      }
    }
  }

  @include breakpoint-min(xlarge) {
    .header-links {
      ul {
        > li {
          & + li {
            margin: 0 0 0 40px;
          }
        }
      }
    }

    .site-nav {
      ul.menu {
        > li {
          & + li {
            margin: 0 0 0 50px;
          }
        }
      }
    }
  }

  @include breakpoint-max(large) {
    .logo {
      @include flex($align: center, $justify: space-between, $wrap: nowrap);
      margin: 0 0 15px;

      > .btn-toggle-menu {
        margin: 0 0 0 50px;
        min-width: 40px;
      }
    }

    .btn-toggle-menu {
      background-color: $brand-color;
      border-radius: 3px;
      color: #ffffff;
      @include flex($align: center, $justify: center);
      min-width: 40px;
      position: relative;
      @include sizes(40px);
    }

    .header-links {
      ul {
        justify-content: flex-start;
        margin: 0 -2.5px -5px;

        > li {
          padding: 0 2.5px;
          margin: 0 0 5px;
          width: 50%;

          > a {
            border: solid 1px $border-color;
            border-radius: 3px;
            font-size: 14px;
            letter-spacing: -1.2px;
            padding: 5px 5px 5px 30px;

            > i {
              left: 5px;
            }
          }
        }
      }
    }

    .site-nav {
      left: 0;
      margin: 10px 0 0;
      padding: 0 15px;
      position: absolute;
      top: 100%;
      width: 100%;
      z-index: 5;

      .toggle-menu {
        display: none;
      }

      ul.menu {
        background-color: #ffffff;
        box-shadow: 10px 10px 10px rgba(#000000, 0.1);
        display: block;

        > li {
          margin: 0;

          & + li {
            border-top: solid 1px $border-color;
            margin: 0;
          }

          > a {
            color: $text-color-dark;
            display: block;
            position: relative;
            padding: 15px;

            &.active,
            &:hover {
              background-color: #f5f5f5;
              color: $brand-color;
            }
          }
        }
      }
    }
  }

  @include breakpoint-max(small) {
    .logo {
      //max-width: 220px;
    }
  }
}
