.section-support {
  padding: 180px 0 0;

  .row {
    margin-bottom: -30px;
  }

  .item {
    margin: 0 0 30px;

    .inner {
      border-radius: 5px;
      box-shadow: 0 0 20px rgba(#000000, 0.1);
      cursor: default;
      @include flex($direction: column, $align: center);
      height: 100%;
      padding: 35px;
      text-align: center;

      > img {
        margin: 0 0 20px;
      }

      > h2 {
        color: $text-color-dark;
        font-size: 28px;
        font-weight: weight(medium);
        margin: 0;

        & + p {
          margin: 5px 0 0;
        }

        & + a {
          margin: 20px 0 0;
        }
      }

      > p {
        color: $text-color-light;
        font-size: 22px;
        font-weight: weight(medium);
        line-height: 1.2em;
        margin: 0;

        & + a {
          margin: 20px 0 0;
        }
      }

      > a {
        display: block;
        font-weight: weight(bold);
        line-height: 1em;
        @include transitions;

        & + a {
          margin-top: 10px;
        }

        &.button {
          background-color: $brand-color-secondary;
          border-radius: 50px;
          color: #ffffff;
          font-size: 24px;
          padding: 15px 30px;

          &:hover {
            background-color: $brand-color;
          }
        }

        &.text {
          color: $brand-color-secondary;
          font-size: 36px;
          font-weight: weight("black");

          > i {
            font-size: 24px;
          }

          &:hover {
            color: $brand-color;
          }
        }
      }
    }
  }

  @include breakpoint-max(large) {
    padding: 170px 0 0;

    .item {
      .inner {
        padding: 25px;

        > a {
          &.text {
            font-size: 28px;

            > i {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}
