.section-location {
  background-color: #ffffff;
  padding: 120px 0;

  .box-image {
    @include flex($justify: flex-end);
  }

  .box-address {
    .inner {
      @include flex($direction: column, $align: flex-start);

      h2 {
        color: $text-color-light;
        font-size: 38px;
        font-weight: weight(light);
        margin: 0 0 20px;
        position: relative;
      }

      h3 {
        color: $text-color-dark;
        font-size: 50px;
        font-weight: weight(bold);
        margin: 0 0 20px;

        & + p,
        & + a {
          margin: 0;
        }
      }

      p {
        color: #555555;
        font-size: 22px;
        line-height: 1.2em;
        margin: 10px 0 0;
      }

      a {
        color: #555555;
        font-size: 22px;
        font-weight: weight("bold");
        line-height: 1.2em;
        margin: 10px 0 0;
        @include transitions;

        &:hover {
          color: $brand-color;
        }
      }
    }
  }

  @include breakpoint-min(xlarge) {
    .box-image {
      img {
        max-width: none;
      }
    }
  }

  @include breakpoint-min(large) {
    .row {
      align-items: center;
    }

    .box-address {
      .inner {
        padding-left: 50px;
      }
    }
  }

  @include breakpoint-max(large) {
    .box-image {
      justify-content: center;
      margin: 50px 0 0;
    }

    .box-address {
      .inner {
        align-items: center;
        text-align: center;

        h2 {
          font-size: 28px;
        }

        h3 {
          font-size: 32px;
        }
      }
    }
  }
}
