.site-footer {
  border-top: solid 1px $border-color;
  margin: 90px 0 0;
  padding: 30px 0;

  p {
    color: $text-color-light;
    font-size: 16px;
    font-weight: weight(medium);
    line-height: 1.2em;
  }

  @include breakpoint-max(large) {
    text-align: center;

    p {
      font-size: 18px;
    }
  }
}